import { Component, Prop, Vue } from 'vue-property-decorator'


@Component
export default class AdminSidebar extends Vue {
  
  expandOnHover = false
  expandWithDelay = false
  mobile = "reduce" 
  reduce = false
  
}
