import { Component, Prop, Vue } from 'vue-property-decorator'


@Component
export default class SubPanel extends Vue {
  @Prop() title!: string
  @Prop() listItens!: Array<object>


}
