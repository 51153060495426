import { Component, Prop, Vue } from 'vue-property-decorator'

import {subPanelListItens1, subPanelListItens2 } from '@/services/links';

//theme components
import AdminNavbar from '@/components/adminNavbar/AdminNavbar.vue'
import Footer from '@/components/footer/Footer.vue';
import SubPanel from '@/components/subPanel/SubPanel.vue'
import Sidebar from '@/components/adminSidebar/AdminSidebar.vue'

import { 
  SizedBox
} from '@/components/wrappers';

@Component({
	components:{
		AdminNavbar,
		SizedBox,
		Footer,
    SubPanel,
    Sidebar
	}
})
export default class PortalAdminContent extends Vue {
  @Prop({default: true}) hasContainer!: boolean
  


  mounted() {
    document.title = 'Administrar Site'
  }

  subPanelListItens1 = subPanelListItens1

  subPanelListItens2 = subPanelListItens2
  
}
